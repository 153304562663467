import type { TenantProfile } from 'app-model.carbon-saver'
import { injectable } from '../../../di'
import { axiosApi } from '../../../resources'

export class TenantProfileResource {
  public static injectable = injectable(TenantProfileResource)

  async readTenantProfile (): Promise<TenantProfile> {
    const response = await axiosApi.get('/tenant-profile')
    const tenantProfile = response.data as TenantProfile
    return tenantProfile
  }
}
