<template>
  <v-dialog class="molecule-confirmation-dialog"
            v-on="$listeners" v-bind="$attrs"
            :width="$attrs.width ? $attrs.width : 580">
    <v-form @submit.prevent="$emit('ok')">
      <component v-if="cardComponent"
                 :is="cardComponent"
                 v-bind="cardComponentProps"
                 :title="title"
                 :subtitle="subtitle"
                 :color="$attrs.color"
                 closeable
                 class="pa-10"
                 @close="$emit('close')">>
      </component>
      <molecule-default-card v-else
                             :title="title"
                             :subtitle="subtitle"
                             :color="$attrs.color"
                             closeable
                             class="pa-10"
                             @close="$emit('close')">
        <div class="body-1" v-if="messageComponent || message">
          <component v-if="messageComponent"
                     :is="messageComponent"
                     v-bind="messageComponentProps"></component>
          <span v-html="message" v-else-if="messageHtml"></span> <span v-else>{{ message }}</span>
        </div>
        <component v-if="contentComponent"
                   :is="contentComponent"
                   v-bind="contentComponentProps"></component>
        <template v-slot:actions>
          <component v-if="actionsComponent"
                     :is="actionsComponent"
                     v-bind="actionsComponentProps"></component>
          <template v-else>
            <atom-button outlined color="primary" large @click="$emit('cancel')">
              {{ effectiveCancelLabel }}
            </atom-button>
            <atom-button color="accent" large type="submit">
              {{ effectiveConfirmLabel }}
            </atom-button>
          </template>
        </template>
      </molecule-default-card>
    </v-form>
  </v-dialog>
</template>

<style scoped lang="scss">
.molecule-confirmation-dialog {

}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import MoleculeDefaultCard from './MoleculeDefaultCard.vue'
import { tl } from '../../plugins/i18next'
import AtomButton from '../atoms/AtomButton.vue'
import type { ComponentPublicInstanceConstructor } from 'vue/types/v3-component-public-instance'

export default defineComponent({
  components: { AtomButton, MoleculeDefaultCard },
  name: 'MoleculeConfirmationDialog',
  props: {
    title: {
      type: String,
      default: () => tl('molecule-confirmation-dialog.title-default', 'confirmation')
    },
    subtitle: {
      type: String
    },
    closeable: {
      type: Boolean,
      default: () => true
    },
    message: {
      type: String,
      default: () => tl('molecule-confirmation-dialog.title-default', 'confirmation')
    },
    messageHtml: {
      type: Boolean,
      default: false
    },
    yesNo: {
      type: Boolean,
      default: false
    },
    confirmLabel: {
      type: String
    },
    cancelLabel: {
      type: String
    },
    messageComponent: {
      type: [String, Object] as PropType<string | ComponentPublicInstanceConstructor>
    },
    messageComponentProps: {
      type: Object as PropType<Record<string, unknown>>
    },
    cardComponent: {
      type: [String, Object] as PropType<string | ComponentPublicInstanceConstructor>
    },
    cardComponentProps: {
      type: Object as PropType<Record<string, unknown>>
    },
    contentComponent: {
      type: [String, Object] as PropType<string | ComponentPublicInstanceConstructor>
    },
    contentComponentProps: {
      type: Object as PropType<Record<string, unknown>>
    },
    actionsComponent: {
      type: [String, Object] as PropType<string | ComponentPublicInstanceConstructor>
    },
    actionsComponentProps: {
      type: Object as PropType<Record<string, unknown>>
    }
  },
  setup: (props) => {
    const effectiveCancelLabel = computed(() => {
      if (props.cancelLabel) {
        return props.cancelLabel
      }
      if (props.yesNo) {
        return tl('common.no', 'Non')
      }
      return tl('common.cancel', 'Annuler')
    })

    const effectiveConfirmLabel = computed(() => {
      if (props.confirmLabel) {
        return props.confirmLabel
      }
      if (props.yesNo) {
        return tl('common.yes', 'Oui')
      }
      return tl('common.ok', 'OK')
    })

    return { effectiveCancelLabel, effectiveConfirmLabel }
  }
})
</script>
