import { injectable } from '../../../di'
import type { UseAccount } from '../use/account'
import { useAccount } from '../use/account'

export class AccountState {
  public static injectable = injectable(AccountState)
  public readonly use: UseAccount

  constructor () {
    this.use = useAccount()
  }
}
