<template>
  <v-list class="molecule-navigation-content" text>
    <v-list-item-group>
      <v-list-item-subtitle>
        <v-list-item-content class="px-2">
          {{ $tl('molecule-navigation-content.user-space', 'espace utilisateur') }}
        </v-list-item-content>
      </v-list-item-subtitle>
      <v-list-item exact :to="{name: 'home'}">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $tl('molecule-navigation-content.home', 'Accueil') }}</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{name: 'projects'}" v-if="hasPermission('project', 'read')">
        <v-list-item-icon>
          <v-icon>mdi-pencil-ruler</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $tl('molecule-navigation-content.projects', 'Projets') }}</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{name: 'tenant-members'}" v-if="hasPermission('tenant-member', 'read')">
        <v-list-item-icon>
          <v-icon>mdi-account-supervisor-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $tl('molecule-navigation-content.tenant-members', 'Membres') }}</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{name: 'tenant-admin'}" v-if="hasPermission('tenant-admin', 'read')">
        <v-list-item-icon>
          <v-icon>mdi-database-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $tl('molecule-navigation-content.tenant-admin', 'Administration') }}</v-list-item-title>
      </v-list-item>
      <v-list-item href="https://lpl.sharepoint.com/sites/Materiautheque/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMateriautheque%2FShared%20Documents%2F08%5FGUIDES%2F2023%20Guide%20e%CC%81co%2Dconception%20Archi%20Permanent%2Epdf&parent=%2Fsites%2FMateriautheque%2FShared%20Documents%2F08%5FGUIDES" v-if="hasPermission('tenant-member', 'read')">
        <v-list-item-icon>
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $tl('molecule-navigation-content.guidelines', 'Guidelines Chanel') }}</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useAuth } from '../../use/auth'

export default defineComponent({
  name: 'MoleculeNavigationContent',
  setup: () => {
    const { hasPermission } = useAuth()
    return { hasPermission }
  }
})
</script>
<style lang="scss">
.molecule-navigation-content {
}
</style>
