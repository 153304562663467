import { TenantProfileResource } from './resources/tenant-profile'
import { TenantContextResource } from './resources/tenant-context'
import { TenantProfileState } from './states/tenant-profile'
import { TenantContextState } from './states/tenant-context'
import { TenantConfigurationResource } from './resources/tenant-configuration'
import { TenantConfigurationState } from './states/tenant-configuration'
import { TenantMemberResource } from './resources/tenant-member'
import { TenantTeamResource } from './resources/tenant-team'
import { TenantMemberState } from './states/tenant-member'
import { TenantTeamState } from './states/tenant-team'
import { TenantMemberService } from './services/tenant-member'
import { TenantTeamService } from './services/tenant-team'

export function provideModule (): void {
  TenantConfigurationResource.injectable.provide()
  TenantProfileResource.injectable.provide()
  TenantContextResource.injectable.provide()
  TenantMemberResource.injectable.provide()
  TenantTeamResource.injectable.provide()

  TenantProfileState.injectable.provide()
  TenantContextState.injectable.provide()
  TenantConfigurationState.injectable.provide()
  TenantMemberState.injectable.provide()
  TenantTeamState.injectable.provide()

  TenantMemberService.injectable.provide()
  TenantTeamService.injectable.provide()
}
