import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import config from '../config'
import type VueRouter from 'vue-router'

export function initSentry (router: VueRouter): void {
  const tracingOrigins: Array<string | RegExp> = []
  if (config.sentry.tracingOrigins != null) {
    for (const tracingOrigin of config.sentry.tracingOrigins) {
      tracingOrigins.push(tracingOrigin)
    }
  }
  tracingOrigins.push(/^\//)

  Sentry.init({
    Vue,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins
      })
    ],
    logErrors: true,
    enabled: config.sentry.enabled,
    dsn: config.sentry.dsn,
    tracesSampleRate: config.sentry.tracesSampleRate,
    environment: config.sentry.environment,
    release: config.app.version
  })
}
