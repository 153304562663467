import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import { injectRequired } from '../../../di'
import { AccountResource } from '../resources/account'

export interface UseAccount {
  disableMyAccount: UseAsyncRunner<void>
}

export interface UseAccountOptions {
  resource?: AccountResource
}

export function useAccount (options?: UseAccountOptions): UseAccount {
  const resource = options?.resource ?? injectRequired(AccountResource)

  const disableMyAccount = useAsyncRunner(async () => await resource.disableMyAccount())

  return {
    disableMyAccount
  }
}
