import type { TenantProfile } from 'app-model.carbon-saver'
import type { TenantProfileResource } from '../resources/tenant-profile'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { refDefault } from '@vueuse/core'

export interface UseTenantProfile {
  tenantProfileOptional: Ref<TenantProfile | undefined>
  tenantProfile: Ref<TenantProfile>
  readTenantProfile: UseAsyncRunner<TenantProfile>
}

export interface UseTenantProfileOptions {
  resource: TenantProfileResource
}

export function useTenantProfile (options: UseTenantProfileOptions): UseTenantProfile {
  const { resource } = options

  const tenantProfileOptional = ref<TenantProfile>()
  const tenantProfile = refDefault<TenantProfile>(tenantProfileOptional, {
    roles: [],
    teams: []
  })

  const readTenantProfile = useAsyncRunner(async () => await resource.readTenantProfile(), tenantProfileOptional)

  return {
    tenantProfileOptional,
    tenantProfile,
    readTenantProfile
  }
}
