<template>
  <v-app :key="keyIndex">
    <v-main>
      <organism-app-bar-with-drawer v-if="hasDrawer || hasAccount" :drawer="hasDrawer">
        <template #drawer v-if="hasDrawer">
          <molecule-navigation-content></molecule-navigation-content>
        </template>
        <v-spacer></v-spacer>
        <div class="d-flex align-center mx-n1">
          <molecule-tenant-profile-menu-button v-if="tenantProfileOptional && tenantContextOptional"
                                               :tenant-profile="tenantProfileOptional"
                                               :tenant-context="tenantContextOptional"
                                               class="mx-1"
                                               :button-class="{'mr-2': $vuetify.breakpoint.smAndDown}"
                                               fab>
          </molecule-tenant-profile-menu-button>
          <molecule-account-menu-button v-if="hasAccount && user != null"
                                        :menu-attrs="{bottom: true, 'offset-y': true}"
                                        :user=user
                                        class="mx-1"
                                        :fab="$vuetify.breakpoint.smAndDown">
          </molecule-account-menu-button>
        </div>
        <template #footer>
          <div class="body-2 pb-4">
            {{ version }}
          </div>
          <div class="body-1">
            {{
              $tl(
                'molecule-app-navigation-drawer.designed-by-community-of-architects',
                'cette application est conçue en collaboration avec la communauté d\'architectes de Carbon Saver'
              )
            }}
          </div>
          <div class="body-1 font-weight-bold pt-4">
            <a class="white--text" href="https://www.carbon-saver.com" target="_blank" rel="noopener"> {{
                $tl(
                  'molecule-app-navigation-drawer.know-more',
                  'en savoir plus'
                )
              }}</a>
          </div>
        </template>
      </organism-app-bar-with-drawer>
      <v-overlay :value="overlay"></v-overlay>
      <router-view></router-view>
      <molecule-confirmation-dialog v-model="confirmationDialog"
                                    v-bind="confirmationDialogOptions"
                                    @close="cancel()"
                                    @ok="ok()"
                                    @cancel="cancel()">
      </molecule-confirmation-dialog>
      <molecule-snackbar v-model="snackbar"
                         v-bind="snackbarOptions">
      </molecule-snackbar>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import vuetify, { HexToRGBA } from './plugins/vuetify'
import { useOverlay } from './use/overlay'
import OrganismAppBarWithDrawer from './components/organisms/OrganismAppBarWithDrawer.vue'
import MoleculeNavigationContent from './components/molecules/MoleculeNavigationContent.vue'
import MoleculeAccountMenuButton from './components/molecules/MoleculeAccountMenuButton.vue'
import { useAuth } from './use/auth'
import type { RouteMeta } from './router'
import { router } from './router'
import MoleculeConfirmationDialog from './components/molecules/MoleculeConfirmationDialog.vue'
import { useConfirmationDialog } from './use/confirmation-dialog'
import MoleculeSnackbar from './components/molecules/MoleculeSnackbar.vue'
import { useSnackbar } from './use/snackbar'
import config from './config'
import { injectRequired } from './di'
import { TenantProfileState } from './modules/tenant/states/tenant-profile'
import { TenantContextState } from './modules/tenant/states/tenant-context'
import { provideModule } from './modules/tenant/module'
import MoleculeTenantProfileMenuButton from './modules/tenant/components/molecules/MoleculeTenantProfileMenuButton.vue'
import { useApp } from './use/app'
import { TenantConfigurationState } from './modules/tenant/states/tenant-configuration'

export default defineComponent({
  name: 'App',
  components: {
    MoleculeTenantProfileMenuButton,
    MoleculeSnackbar,
    MoleculeConfirmationDialog,
    MoleculeAccountMenuButton,
    MoleculeNavigationContent,
    OrganismAppBarWithDrawer
  },
  setup: () => {
    const drawer = ref<boolean>(false)
    const { user } = useAuth()

    const { keyIndex } = useApp()

    // TODO: load tenant-common module only if tenant
    provideModule()
    const { readTenantProfile, tenantProfileOptional } = injectRequired(TenantProfileState).use
    const { readTenantContext, tenantContextOptional } = injectRequired(TenantContextState).use
    const { init18next } = injectRequired(TenantConfigurationState).useCustomI18nResource

    if (config.tenant.tenantId !== undefined) {
      readTenantProfile.runOnce()
      readTenantContext.runOnce()
      init18next.runOnce()
      init18next.runOnce(true)
    }

    const version = config.app.version

    router.afterEach((to) => {
      hasDrawer.value = (to.meta as RouteMeta | null)?.drawer !== false
      hasAccount.value = (to.meta as RouteMeta | null)?.account !== false
    })

    const hasDrawer = ref(false)
    const hasAccount = ref(false)

    const { overlay } = useOverlay()

    const {
      visible: confirmationDialog,
      ok,
      cancel,
      dialogOptions: confirmationDialogOptions
    } = useConfirmationDialog()
    const { visible: snackbar, snackbarOptions } = useSnackbar()

    const rgbInlineId = 'vuetify-theme-stylesheet-rgb-inline'

    const existingStyle: HTMLStyleElement | null = document.head.querySelector('style#' + rgbInlineId)

    let styleEl: HTMLStyleElement
    if (existingStyle) {
      styleEl = existingStyle
    } else {
      styleEl = document.createElement('style')
      styleEl.type = 'text/css'
      styleEl.id = 'vuetify-theme-stylesheet-rgb-inline'

      if (vuetify.framework.theme.options.cspNonce) {
        styleEl.setAttribute('nonce', vuetify.framework.theme.options.cspNonce)
      }

      document.head.appendChild(styleEl)
    }

    watch(() => vuetify.framework.theme.currentTheme, () => {
      const regexp = /\s*(.+): (#[0-f]{6})/gm

      const rgbRules: Record<string, string> = {}

      for (let i = 0; i < document.styleSheets.length; i++) {
        const sheet = document.styleSheets.item(i)
        if (!sheet || !sheet.ownerNode) continue
        const ownerNode = sheet.ownerNode as HTMLElement

        if (ownerNode.id === 'vuetify-theme-stylesheet') {
          const textContent = ownerNode.textContent
          if (textContent) {
            const matches = textContent.matchAll(regexp)
            for (const match of matches) {
              const rgba = HexToRGBA(match[2])
              rgbRules[match[1] + '-rgb'] = `${rgba.r}, ${rgba.g}, ${rgba.b}`
            }
          }
        }
      }

      let rule = ':root {\n'

      for (const key of Object.keys(rgbRules)) {
        const v = rgbRules[key]
        rule += `  ${key}: ${v};\n`
      }

      rule += '}'

      styleEl.textContent = rule
    }, { immediate: true })

    return {
      overlay,
      confirmationDialog,
      confirmationDialogOptions,
      ok,
      cancel,
      snackbar,
      snackbarOptions,
      version,
      user,
      keyIndex,
      tenantProfileOptional,
      tenantContextOptional,
      drawer,
      hasDrawer,
      hasAccount
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
