import './plugins'
import './assets'
import 'flag-icons'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18next'
import { router } from './router'

import './styles/index.scss'

import App from './App.vue'

import { initSentry } from './init/sentry'

import Vue from 'vue'
import errorHandler from './errorHandler'
import * as keycloakAccount from './modules/keycloak-account/module'
import * as account from './modules/account/module'
import * as project from './modules/project/module'

Vue.config.errorHandler = errorHandler

export default new Vue({
  template: '<App/>',
  vuetify,
  router,
  i18n,
  render (createElement) {
    return createElement(App)
  },
  setup: () => {
    keycloakAccount.provideModule()
    account.provideModule()
    project.provideModule()

    initSentry(router)
  }
}).$mount('#app')
