import { injectable, injectRequired } from '../../../di'
import { TenantProfileResource } from '../resources/tenant-profile'
import type { UseTenantProfile } from '../use/tenant-profile'
import { useTenantProfile } from '../use/tenant-profile'

export class TenantProfileState {
  public static injectable = injectable(TenantProfileState)
  public readonly use: UseTenantProfile

  constructor () {
    const profileResource = injectRequired(TenantProfileResource)

    this.use = useTenantProfile({ resource: profileResource })
  }
}
