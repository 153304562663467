<template>
  <v-snackbar class="molecule-snackbar pa-0"
              v-model="valueModel"
              :multi-line="!!title && !!message"
              v-bind="$attrs">
    <div class="d-flex flex-row justify-center">
      <div v-if="icon" class="d-flex flex-column justify-center ml-n2 pr-2">
        <v-icon x-large>{{ icon }}</v-icon>
      </div>
      <div v-if="title || message" class="d-flex flex-column justify-center">
        <div v-if="title">
          <h5>{{ title }}</h5>
        </div>
        <div v-if="messageString">
          <span v-if="!text" v-html="messageString"></span> <span v-else>{{ messageString }}</span>
        </div>
        <div v-if="messageArray">
          <ul>
            <li :key="$index" v-for="(messageString, $index) of messageArray">
              <span v-if="!text" v-html="messageString"></span> <span v-else>{{ messageString }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="button" class="d-flex flex-column justify-center">
        <v-btn fab dark text @click="handleButtonClick" class="vertical-center">
          {{ button.text }}
        </v-btn>
      </div>
      <div v-if="closeable" class="d-flex flex-column justify-center mr-n2 pl-2">
        <v-btn fab small dark text @click.native="valueModel = false" class="vertical-center">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </v-snackbar>
</template>

<style scoped lang="scss">
.molecule-snackbar::v-deep {
  .v-snack__action {
    display: none
  }

  .v-snack__content {
    height: initial !important;

    .btn {
      margin-left: 0 !important;
    }
  }
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import type { SnackbarButton } from '../../use/snackbar'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'MoleculeSnackbar',
  props: {
    value: {
      type: Boolean
    },
    title: {
      type: String
    },
    message: {
      type: [String, Array] as PropType<string | string[]>
    },
    icon: {
      type: String
    },
    closeable: {
      type: Boolean,
      default: false
    },
    button: {
      type: Object as PropType<SnackbarButton>
    },
    text: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const valueModel = useVModel(props, 'value', emit, { eventName: 'input' })

    const handleButtonClick = () => {
      return props.button?.action()
    }

    const messageString = computed(() => {
      const messageProp = props.message
      if (Array.isArray(messageProp)) {
        return undefined
      }
      return messageProp
    })

    const messageArray = computed(() => {
      const messageProp = props.message
      if (Array.isArray(messageProp)) {
        return messageProp
      }
      return undefined
    })

    return { valueModel, handleButtonClick, messageString, messageArray }
  }
})
</script>
