<template>
  <div>
    <molecule-app-bar :nav-icon="mobile" :carbon-saver-logo="mobile"
                      app fixed hide-on-scroll
                      @navIconClick="drawerOpen = !drawerOpen"
                      v-on="$listeners" v-bind="$attrs">
      <slot></slot>
    </molecule-app-bar>
    <molecule-app-navigation-drawer v-model="drawerOpen"
                                    v-if="drawer"
                                    :mobile="mobile"
                                    :carbon-saver-logo="!mobile"
                                    left dark
                                    v-bind="drawerAttrs"
                                    v-on="drawerListeners">
      <slot name="drawer"></slot>
      <template v-slot:actions>
        <slot name="drawer-actions"></slot>
      </template>
      <template v-slot:footer v-if="$slots.footer">
        <slot name="footer"></slot>
      </template>
    </molecule-app-navigation-drawer>
  </div>
</template>
<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent, ref } from 'vue'
import MoleculeAppBar from '../molecules/MoleculeAppBar.vue'
import vuetify from '../../plugins/vuetify'
import MoleculeAppNavigationDrawer from '../molecules/MoleculeAppNavigationDrawer.vue'

export default defineComponent({
  name: 'OrganismAppBarWithDrawer',
  components: { MoleculeAppNavigationDrawer, MoleculeAppBar },
  props: {
    dark: {
      type: Boolean as PropType<boolean>
    },
    drawer: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    drawerAttrs: {
      type: Object as PropType<Record<string, unknown>>
    },
    drawerListeners: {
      type: Object as PropType<Record<string, unknown>>
    }
  },
  setup: () => {
    const drawerOpen = ref(false)

    const mobile = computed(() => {
      return vuetify.framework.breakpoint.smAndDown
    })

    return { drawerOpen, mobile }
  }
})
</script>
<style lang="scss">
.organism-app-bar {
}
</style>
