<template>
  <v-menu v-model="menu" class="molecule-tenant-profile-menu-button" :close-on-content-click="false" bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <atom-tenant-button
        :tenant-context="tenantContext"
        :class="buttonClass"
        v-bind="{...attrs, ...$attrs}"
        v-on="on">
      </atom-tenant-button>
    </template>
    <molecule-default-card
      class="pa-4"
      closeable
      @close="menu = false"
      :title="$tl('molecule-tenant-profile-menu-button.title', 'votre organisation')" min-width="350">
      <v-row v-if="tenantContext.generalInformation.iconUrl || tenantContext.generalInformation.iconUrlHighResolution">
        <v-col>
          <v-img v-if="tenantContext.generalInformation.iconUrlHighResolution"
                 :src="tenantContext.generalInformation.iconUrlHighResolution"
                 :aspect-ratio="1"
                 position="start center"
                 contain
                 max-height="150" max-width="310"></v-img>
          <v-img v-else-if="tenantContext.generalInformation.iconUrl"
                 :src="tenantContext.generalInformation.iconUrl"
                 max-height="40" max-width="40"></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="subtitle-1">
            {{ tenantContext.generalInformation.displayName }}
          </div>
          <div>
            {{ tenantContext.members.length }} membres
          </div>
        </v-col>
      </v-row>
      <v-row v-if="tenantContext.generalInformation.manager">
        <v-col>
          <div class="subtitle-1">{{ $tl('molecule-tenant-profile-menu-button.manager-subtitle', 'gestionnaire') }}</div>
          <atom-tenant-member-badge small :value="tenantContext.generalInformation.manager"></atom-tenant-member-badge>
        </v-col>
      </v-row>
      <v-row v-if="tenantContext.generalInformation.contactEmail">
        <v-col>
          <div class="subtitle-1">{{ $tl('molecule-tenant-profile-menu-button.contact-subtitle', 'contact') }}</div>
          <div class="d-flex align-end pt-2">
            <v-icon left color="primary">mdi-email</v-icon>
            <a :href="`mailto:${tenantContext.generalInformation.contactEmail}`">
              {{ tenantContext.generalInformation.contactEmail }} </a>
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions v-if="hasPermission('tenant-configuration', 'update')">
        <v-spacer></v-spacer>
        <atom-button color="accent" @click="menu = false; $router.push({name: 'tenant-configuration'})">
          {{ $tl('molecule-tenant-profile-menu-button.configure-organisation', 'configurer l\'organisation') }}
        </atom-button>
      </template>
    </molecule-default-card>
  </v-menu>
</template>

<style lang="scss" scoped>
.molecule-tenant-profile-menu-button {

}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import type { TenantContext, TenantProfile } from 'app-model.carbon-saver'
import AtomButton from '../../../../components/atoms/AtomButton.vue'
import AtomTenantButton from '../../components/atoms/AtomTenantButton.vue'
import MoleculeDefaultCard from '../../../../components/molecules/MoleculeDefaultCard.vue'
import AtomTenantMemberBadge from '../../components/atoms/AtomTenantMemberBadge.vue'
import { useAuth } from '../../../../use/auth'

export default defineComponent({
  name: 'MoleculeTenantProfileMenuButton',
  components: { AtomTenantMemberBadge, MoleculeDefaultCard, AtomTenantButton, AtomButton },
  props: {
    tenantProfile: {
      type: Object as PropType<TenantProfile>,
      required: true
    },
    tenantContext: {
      type: Object as PropType<TenantContext>,
      required: true
    },
    buttonClass: {
      type: [Object, Array] as PropType<Record<string, boolean> | string[]>
    }
  },
  setup: () => {
    const menu = ref<boolean>(false)

    const { hasPermission } = useAuth()

    return { menu, hasPermission }
  }
})
</script>
